import React from "react";
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

import useTranslations from "../components/useTranslations";
import { LocaleContext } from "../components/layout";
import Seo from "../components/seo";
import Work from "../templates/work";

export default function Home({ data }) {
  const { locale, url } = React.useContext(LocaleContext);
  const { homepage } = useTranslations();

  return (
    <>
      <Seo
        url={url}
        lang={locale}
        title={homepage}
        bodyClass="home"
        schemaOrg
        description={data.meta.frontmatter.metaDescription}
      />
      <Work data={data} isHomepage={true} />
    </>
  );
}

export const query = graphql`
  query ($locale: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    meta: markdownRemark(
      frontmatter: { template: { eq: "about" } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        metaDescription
      }
    }
    markdownRemark(
      frontmatter: { id: { eq: 1 } }
      fields: { locale: { eq: $locale } }
    ) {
      html
      fields {
        baseSlug
      }
      frontmatter {
        id
        title
        description
        author
        type
        video
        ad
        year
        length
        hashtags
        link
        external
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        frontmatter: { author: { ne: null } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            id
            title
          }
          fields {
            baseSlug
          }
        }
      }
    }
    allHashtags(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          locale
          isSimplified
          hashtags {
            hashtag
            shortcut
            text
            id
          }
        }
      }
    }
  }
`;
